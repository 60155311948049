/* * {
  outline: 1px solid rgba(255, 0, 0, 0.214);
} */

body {
  margin: 0;
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e1e1e;
  margin: 0;
  height: 100%;
  overflow: hidden
}

a {
  all: unset
}

p {
  font-size: 14px;
  cursor: auto;
}

h4 {
  color: white;
  padding-bottom: 4px;
  font-weight: 300;
  font-size: 22px;
  margin: 0px;
}

.gray {
  color: rgb(183, 183, 183);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-text {
  text-align: center;
}

.sign-in-button {
  display: flex;
  flex-direction: row;
}

.google-icon {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  border: 1pt solid #3b3b3b;
  background-color: #262626;
  border-radius: 2px 0px 0px 2px;
  border-right: none;
}

.sign-in-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-card {
  width: 350px;
  height: 400px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #0c0c0c;
  border: 1pt dashed #3b3b3b;
  border-radius: 15px;
}

.sign-in-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  position: relative;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  background-color: #1e1e1e;
  border-bottom: 1pt solid #4e4e4e;
}

.dropdown {
  width: 180px;
  display: flex;
  flex-direction: column;
  background-color: #3b3b3b;
  border-radius: 2px;
  position: absolute;
  top: 65px;
}

.tree-list {
  width: 300px;
}

.dropdown-btn {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 8px;
  cursor: pointer;
}

.dropdown-btn:hover {
  background-color: #131313;
}

.nav-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-left-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-right-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.controls {
  max-width: 400px;
  flex: 1;
  display: flex;
  gap: 8px;
}

.idea-input {
  flex: 1;
  padding-left: 8px;
  border: none;
  border-radius: 2px;
  background-color: #1e1e1e;
  border: 1pt solid #6464647e;
  color: white;
}

.idea-text {
  font-size: 19px;
  color: white;
  font-weight: 500;
  margin: 0px;
}

input {
  all: unset;
}

.textarea-container {
  width: 100%;
  position: relative;
  display: flex;
}

.textarea-mirror {
  width: 100%;
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 19px;
  font-weight: 500;
  resize: none;
  position: absolute;
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

textarea {
  padding: 0;
  width: 100%;
  border: none;
  background-color: transparent;
  color: #00ff6e;
  font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 19px;
  font-weight: 500;
  resize: none;
}

textarea:focus {
  outline: none;
}

.node-input::placeholder {
  font-size: 19px;
}

.node-input:focus {
  outline: none
}

.toolong {
  color: #c76151;
}

.idea-input:focus {
  border-color: #ffffff5b;
  outline: none;
}

.toolong:focus {
  border-color: #c76151ad;
  outline: none;
}

button {
  padding: 8px 12px 8px 12px;
  color: white;
  border-radius: 2px;
  border: none;
  border: 1pt solid #3b3b3b;
  background-color: #3b3b3b;
  cursor: pointer;
}

.zoom-btn {
  width: 100px;
  height: 40px;
}

.zoom-controls {
  display: flex;
  gap: 8px;
  position: absolute;
  left: 25px;
  bottom: 25px;
}

.control-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.card-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 2px;
  align-self: flex-start;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 12px 12px 12px 12px;
}

.button-icon {
  opacity: 25%;
}

.green-btn {
  background-color: #008f3e;
  border: 1pt solid #008f3e;
}

.green-btn:hover {
  background-color: #005926;
}

.custom-rounded-corners {
  border-radius: 0px 2px 2px 0px;
}

button:hover {
  background-color: #2a2a2a;
}

.button-text {
  font-weight: 700;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.loading-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rd3t-link {
  stroke: #4e4e4e !important;
}

.tree-container {
  width: 100vw;
  height: 100vh;
}

.custom-node-container {
  overflow: visible;
  cursor: grab;
}

.loading {
  padding: 2px;
  background-color: #ffcc00;
  border: none;
}

.loading-confirm {
  background-color: #ffcc00;
  border: none;
}

.loading-confirm:hover {
  background-color: #ffcc00;
  border: none;
}

.loading:hover {
  background-color: #ffcc00;
  border: none;
}

.card {
  margin-top: 24px;
  margin-left: -2px;
  padding: 24px;
  background-color: #1b1b1b;
  border: 1pt #4e4e4e;
  border-style: solid;
  border-radius: 10px;
  cursor: default;
}

.mobile-message {
  width: 250px;
  text-align: center;
  color: white;
  font-weight: 500;
}

@media (width > 875px) {
  .mobile-message {
    display: none;
  }
}

@media (width < 875px) {
  body {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tree-container {
    display: none;
  }
}